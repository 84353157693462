import React from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import Classes from "./pages/classes/Classes";
import { Navigation } from "./components/Navigation";
import Dashboard from "./pages/Dashboard";
import Subjects from "./pages/subjects/Subjects";
import Teachers from "./pages/teachers/Teachers"
import NotFound from "./pages/NotFound";
import Register from "./pages/Register"
import Login from "./pages/Login";
import Grading from "./pages/exams/Grading"
import Students from "./pages/students/Students"
import Exams from "./pages/exams/Exams"
import Scores from "./pages/exams/Scores"
import Reports from "./pages/exams/Reports"
import SubjectMappings from './pages/subjects/SubjectMappings'
import Sms from "./pages/sms/Sms"
import OverallGrading from "./pages/exams/OverallGrading"
import { useState } from "react";
import { USER_ID_LOCALSTORAGE_KEY } from './configs/constants';
import { saveToLocalStorage, getFromLocalStorage } from './services/localStorageService';
import LandingPage from "./pages/LandingPage";
import Guardians from "./pages/students/Guardians";
import PasswordReset from "./pages/PasswordReset"


function App() {
  console.log('App rendered')

  const [isLoggedIn, setIsLoggedIn] = useState(getFromLocalStorage(USER_ID_LOCALSTORAGE_KEY)!=null ? true : false);

  const logCustomerOut = ()=>{
    localStorage.clear();
    setIsLoggedIn(false)
  }



 

  return (
    <div className="flex h-screen">
      <Navigation isLoggedIn={isLoggedIn} logOut={() => logCustomerOut()} />
      <Routes>
      {!isLoggedIn && (
        <>
        <Route
          path="/auth"
          element={<LandingPage onLogin={() => setIsLoggedIn(true)} />}
        />
        <Route
          path="/register"
          element={<Register />}
        />
        </>
        
      )}

      {isLoggedIn && (
        <>
          <Route
            path="/home"
            element={<Dashboard logout={() => logCustomerOut()} />}
          />
          <Route path="/classes" element={<Classes />} />
          <Route path="/subjects" element={<Subjects />}></Route>
          <Route path="/teachers" element={<Teachers />}></Route>
          <Route path="/subject-grading" element={<Grading />}></Route>
          <Route path="/overall-grading" element={<OverallGrading />}></Route>
          <Route path="/students" element={<Students />}></Route>
          <Route path="/guardians" element={<Guardians />}></Route>
          <Route path="/exams" element={<Exams />}></Route>
          <Route path="/scores" element={<Scores />}></Route>
          <Route path="/reports" element={<Reports />}></Route>
          <Route path="/subjectmappings" element={<SubjectMappings />}></Route>
          <Route path="/bulksms" element={<Sms />}></Route>
         
        </>
      )}

      {/* <Route path="*" element={<Navigate to={isLoggedIn ? "/home" : "/auth"} />} /> */}

      <>
            {/* Your existing routes for non-logged in users */}
            
            {/* Route for the dynamic parameter component */}
            <Route path="/reset-password/:id/:code" element={<PasswordReset />} />
            <Route path="*" element={<Navigate to={isLoggedIn ? "/home" : "/auth"} />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>


    </Routes>
    </div>
    
  );


  // #### second option
  // return isLoggedIn ? (
  // <div className="flex h-screen">
  //   <Navigation isLoggedIn={isLoggedIn} logOut={() => setIsLoggedIn(false)}/>


  //   <Routes>

  //     <Route path="/" element={<Dashboard />}></Route>
  //     <Route path="/home" element={<Dashboard />}></Route>
  //     <Route path="/classes" element={<Classes />}></Route>
  //     <Route path="/subjects" element={<Subjects />}></Route>
  //     <Route path="/teachers" element={<Teachers />}></Route>
  //     <Route path="/subject-grading" element={<Grading />}></Route>
  //     <Route path="/overall-grading" element={<OverallGrading />}></Route>
  //     <Route path="/students" element={<Students />}></Route>
  //     {/* <Route path="/students/:classId" element={<ListStudents />}></Route> */}
  //     <Route path="/exams" element={<Exams />}></Route>
  //     <Route path="/scores" element={<Scores />}></Route>
  //     <Route path="/reports" element={<Reports />}></Route>
  //     <Route path="/subjectmappings" element={<SubjectMappings />}></Route>
  //     <Route path="/bulksms" element={<Sms />}></Route>
  //     <Route path="*" element={<NotFound />}></Route>
  //   </Routes>
  // </div>
  // ) : (
  // <div className="flex h-screen">
  //   <Routes>
  //     {/* <Route path="/login" element={<Login login={()=>setIsLoggedIn(true)} />}></Route> */}
  //     <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} />} />
  //   </Routes>
  // </div>
  // );






  // ###### first option
  // return (
  //   <>
  //   <div className="flex h-screen">
  //     <Navigation />
  //     <Routes>
  //     <Route path="/login" element={<Login />}></Route>
  //       <Route path="/home" element={<Dashboard />}></Route>
  //       <Route path="/classes" element={<Classes />}></Route>
  //       <Route path="/pages" element={<Pages />}></Route>
  //       <Route path="/media" element={<Media />}></Route>
  //       <Route path="/students/:classId" element={<ListStudents />}></Route>
  //       <Route path="*" element={<NotFound />}></Route>
  //     </Routes>
  //   </div>

  //   </>
  // );
}

export default App;

//

















